import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ProgressToasterService } from '../services/progress-toaster.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-progress-toaster',
    templateUrl: './progress-toaster.component.html',
    styleUrls: ['./progress-toaster.component.scss'],
})
export class ProgressToasterComponent implements OnInit, OnDestroy {
    isVisible = false;
    disableClose = false;
    message: string;
    icon: string;
    progress: number;
    isInfiniteProgress = true;
    errorMessage: string;
    successMessage: string;

    subscription = new Subscription();

    constructor(private progressToasterService: ProgressToasterService) {}

    ngOnInit(): void {
        this.subscription = this.progressToasterService.toasterState$
            .subscribe(state => {
                this.isVisible = state.isVisible;
                this.message = state.message;
                this.progress = state.progress;
                this.icon = state.icon;
                this.isInfiniteProgress = state.isInfiniteProgress;
                this.errorMessage = state.errorMessage;
                this.successMessage = state.successMessage;
            });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.progressToasterService.functionSubscription.unsubscribe();
    }

    closeProgressToaster() {
        this.progressToasterService.hideProgressToaster();
    }
}
