import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressToasterService } from './services/progress-toaster.service';
import { ProgressToasterComponent } from './components/progress-toaster.component';

@NgModule({
    declarations: [
        ProgressToasterComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
    ],
    exports: [
        ProgressToasterComponent
    ],
    providers: [
        ProgressToasterService
    ]
})
export class ProgressToasterModule {
}
