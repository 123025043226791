<div class="progress-toaster p-4" *ngIf="isVisible">
    <i class="close close-progress-toaster material-icons-outlined" (click)="closeProgressToaster()">close</i>
    <div class="d-flex justify-content-start align-items-center progress-toaster-text mb-2" translate>
        <i class="material-icons mr-3" *ngIf="icon">{{ icon }}</i>
        <span translate class="message">{{ message }}</span>
    </div>
    <ng-container [ngTemplateOutlet]="isInfiniteProgress ? infiniteProgressBar : progressBar"></ng-container>
    <p *ngIf="errorMessage || successMessage"
        [ngClass]="{ 'error-message': errorMessage, 'success-message': successMessage }" translate
    >{{errorMessage || successMessage}}</p>
</div>

<ng-template #infiniteProgressBar>
    <div class="progress-bar-container">
        <div class="infinite-progress-bar"></div>
    </div>
</ng-template>

<ng-template #progressBar>
    <div class="progress-bar-container">
        <div class="progress-bar" [style.width.%]="errorMessage || successMessage ? 100 : progress" [ngClass]="{'error-progress-bar': errorMessage}"></div>
    </div>
    <span class="progress-status" *ngIf="!errorMessage && !successMessage">{{progress}}%</span>
</ng-template>
