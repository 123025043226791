/* src/app/shared/components/progress-toaster/components/progress-toaster.component.scss */
.progress-toaster {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  padding: 0.25rem;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}
.progress-toaster .close-progress-toaster {
  font-size: 1rem;
  right: 0.5rem;
  bottom: 0.5rem;
}
.progress-bar-container {
  width: 100%;
  border-radius: 4px;
  background-color: rgba(211, 211, 211, 0.61);
}
.progress-bar-container .infinite-progress-bar {
  position: relative;
  height: 4px;
  border-radius: 4px;
  background:
    linear-gradient(
      90deg,
      #41BCD9,
      #265ca0);
  animation: borealisBar 2s linear infinite;
}
.progress-bar-container .progress-bar {
  position: relative;
  height: 4px;
  border-radius: 4px;
  background:
    linear-gradient(
      90deg,
      #41BCD9,
      #265ca0);
  transition: width 0.4s ease;
}
.progress-bar-container .error-progress-bar {
  background: #ff3333 !important;
}
.error-message {
  color: #ff3333;
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0;
}
.success-message {
  color: #6c757d;
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0;
}
.progress-status {
  display: flex;
  flex-direction: row-reverse;
  color: #6c757d;
  font-weight: 600;
  font-size: 0.75rem;
}
@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}
/*# sourceMappingURL=progress-toaster.component-FUABICF7.css.map */
